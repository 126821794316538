export default {
  tabbar: {
    首页: '首页',
    市场: '市场',
    任务: '任务',
    团队: '团队',
    我的: '我的'
  },
  navbar: {
    充值: '充值',
    提现: '提现',
    帮助: 'FAQ',
    分享: '邀请',
	推广: '推广',
    活动: '游戏',
	游戏: '游戏',
  },	 
  payment: {
    付款方式详情: '付款方式详情',
    银行卡: '银行卡',
    钱包地址: '提现方式',
    交易密码: '交易密码',
    保存: '保存'
  },
  market: {
    市场: '市场',
    名称: '名称',
    价格: '价格',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"邀請好友註冊領取佣金",
  领取:"领取",
  未满足条件:"未满足条件",
  总收入:"总收入",
  投资建议:"※进行复利投资计划，收益更可观",
  语言切换: '语言切换',
  POS区块节点挖矿: '人工智能量化交易详情',
  节点类型: '机器人\n类型',
  加入价格: '购买\n价格',
  质押资金: 'POS\n资金',
  质押周期: '周期\n(天)',
  每日收入: '每日\n收入',
  累计积分: '累计\n积分',
  代理等级: '代理\n等级',
  下级折扣比: '下级\n折扣',
  代理折扣比: '代理\n折扣',
  下属等级: '下属\n等级',
  代理佣金比率: '代理\n佣金\n比率',
  下属每日收入: '下属\n每日\n收入',
  代理佣金: '代理\n佣金',
  引用对象: '引用\n对象',
  积分等级折扣规则: '积分等级折扣规则',
  积分等级折扣规则介绍: '成功参与Ai量化交易的用户将获得对应的账户积分，并且他们的代理上级用户也可以增加对应的积分（适用于直属下属15%），当账户总积分满足对应条件后代理账号的等级将会提升，不同级别的代理账号可以获得不同比例的价格折扣优惠。相关积分折扣详情如下：',
  积分等级折扣规则介绍2: '具有不同关系（直接）的用户将收到根据比例给下属参与Ai量化交易的折扣和代理级折扣。 其他高层也将获得根据自己代理相应比例的折扣等级。 参与Ai量化交易价格优惠：拥有对应代理等级的用户参与Ai量化交易时将获得相应的代理级优惠权利',
  团队: '团队',
  代理佣金制度: '代理佣金制度',
  代理佣金说明详情: 'A获得的LV1代理比例：8%，A获得的LV2代理比例：3%，A获得的LV3代理比例：2%。例子：B、C、D每日盈利为：100 USD，A获得的LV1代理奖励：8%*100=8 USD。A获得的LV2代理奖励：3%*100=3 USD。A获得的LV3代理奖励：2%*100=2 USD。总计 佣金收入为：8+3+2=13 USD。',
  代理结构图示: '代理结构图示',
  代理结构图示说明: 'A代表你， A邀请B(A的下属是B)，B邀请C(B的下属是C)，C邀请D(C的下属是D)。 A可以有多个下属(B1,B2,B3,B4,B5,B6,etc.)，B(B1,B2,B3) 也可能有多个下属(C1,c2,c3,c4,c5,c6,etc.) C... D...',
  高级代理佣金说明: '高级代理佣金说明',
  高级代理佣金说明详情: 'A 代表你， A邀请B(A 的下属是 B)， B 邀请 C(B 的下属是C)， C 邀请 D (C的下属是D) 。上级代理将根据本身账号和下属的账号参与节点挖矿的总金额 比率获得不同的代理佣金收入(%)。 A对比下属成员每日收入获得代理奖励相应的比例。 参与的节点必须处于正常活动状态。 代理收入由INGSAI MINING提供所有财务支出，并不会影响正常下属成员的每日收入。',
  高级代理佣金说明详情2: '例子：高级代理总参与挖矿金额(A)>30%下层会员总挖矿金额(B/C/D)(例：A>30% of B/C/D)',
  积分: '积分',
  规则: '规则',
  会员级别: '会员级别',
  经验: '积分总值',
  代理信息: '会员信息',
  更多: '更多',
  团队奖励: '团队佣金',
  昨天: '昨天',
  本星期: '本星期',
  全部: '全部',
  机器人说明: '可以同时购买多个量化交易套餐，Robot机器人每个帐户只能购买10次。谨记：购买量化机器人后不要忘记点击激活策略，否则将没有收入。量化机器人有效时间按购买时间计算，请及时激活量化交易策略，如果量化机器人过期后还未激活,您将失去您的购买量化交易策略套餐的成本!!! 请所有参与Plus500Ai量化交易的用户仔细阅读此条例！！',
  会员体验: '会员体验',
  机器人采购: '机器人购买',
  机器人启动押金: '节点POS金额',
  机器人礼物: '节点礼物',
  机器人订单: '节点订单',
  一次性总和: '显示',
  总计: '总计',
  
  被激活: '已POS',
  工作中: '已激活',
  暂停: '暂停POS',
  结束: '已到期',
  刷新: '刷新',
  来源: '来源',
  价格: '量化套餐价格',
  启动押金: 'POS金额',
  剩余时间: '剩余时间',
  激活时间: '激活时间',
  号: '号',
  我的资产: '我的资产',
  退出: '登出',
  邀请码: '邀请码',
  我的钱包余额: '我的钱包余额',
  我的机器人仓库: '我的机器人列表',
  收益面板: '收益面板',
  去提款: '提款',
  昨天代理返利: '昨天代理佣金',
  累计代理返利: '累计代理佣金',
  今天机器人收益: '今天交易收益',
  累计机器人收益: '累计交易收益',
  累计机器人回扣: '累计交易佣金',
  累计总回报: '累计总收益',
  自动订单机器人数量: '已激活的量化交易机器人',
  我的付款方式: '我的付款方式',
  个人收入记录: '个人收入',
  团队收入记录: '团队收入',
  记录详情:'记录详情',
  充值记录: '充提记录',
  我的团队成员: '我的团队成员',
  活动中心: '规则介绍',
  修改登录密码: '登陆密码设置',
  交易密码管理: '交易密码设置',
  机器人: 'Ai交易策略',
  机器人性能: 'Ai机器人面板',
  机器人价格: '交易策略价格',
  有效期: '交易有效时间',
  钱包地址: '钱包地址',
  天: '天',
  去购买: '购买',
  买个机器人: '购买量化交易机器人',
  我的钱包余额: '我的账户余额',
  预计日收益: '预计日收益',
  启动保证金: '启动保证金',
  请输入购买数量: '请输入购买数量',
  个机器人: '量化交易机器人',
  机器人购买后需要激活才能工作: '购买节点后需要POS对应金额才能开始获益',
  机器人激活需要从余额中扣除相应的启动押金:
    '节点POS挖矿需要从账户余额中扣除相应的POS金额',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    '暂停节点POS挖矿时可以立刻退回POS的金额',
  购买: '购买',
  点击去了解我们: '量化交易',
  购买机器人: '购买机器人',
  快速入口: '快速入口',
  '日/个人受益': '日/个人受益',
  购买教程: 'AI交易机器人列表',
  激活金额: 'POS费',
  订单数量: '订单数量',
  我们的合作伙伴: '我们的加密市场合作伙伴',
  提款: '提款',
  提款金额: '提款金额',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: '提现说明',
  说明1: '1.您可以一键选择或手动输入充值金额',
  说明2: '2.推荐使用USD(TRC20)，转账手续费最低',
  说明3: '3.客户服务时间为中美洲时间周一至周五AM8至PM6',
  说明4: '使用USD充值的用户需要上传正确的转账成功截图和TXID，平台审核通过后会成功到账',
  说明5: '4.最低充值8 USD,方便系统快速审核处理到账',
  说明6: '5.如您对充值有任何问题请及时联系在线客服协助处理',
  确认提款: '确认提款 ',
  请输入手机号码:'手机号码',
  请输入提现金额: '提现金额 ',
  提现费:'提现费',
  提现须知: '请仔细阅读提款规则',
  TIPS1: '1:每日取款审核时间为周一至周五AM8至PM6。周末可以提款，提款审核时间为周一',
  TIPS2: '2:7X24小时制，随时取款，最迟24小时到账，最短5分钟到账',
  TIPS3: '3:在线客服服务时间：周一至周五AM8至PM6',
  TIPS4: '4:由于USD转账需要成本，用户提款时平台收取提款费',
  TIPS5: '5:最低提款金额为10 USD',
  钱包余额: '钱包余额',
  输入数量:"输入数量",
  数量:"数量",
  手机号码:"手机号码",
  手机号码已存在:"手机号码已注册",
  邮箱:"邮箱",
  输入手机号码: '输入手机号码',
  请输入登录密码: '登录密码',
  登录: '登录',
  输入账号: '输入账号',
  密码: '密码',
  点击注册: '还没有帐户？立即创建一个！',
  忘记密码: '忘记密码',
  重新登录: '重新登入',
  密码找回成功: '密码找回成功',
  找回成功请重新登录: '找回成功 请重新登录',
  确认: '确认',
  发送验证码: '发送验证码',
  再次输入密码: '确认密码',
  输入验证码: '输入邮箱CAPTCHA',
  输入手机验证码:"输入手机验证码",
  输入手机号: '输入手机号',
  输入密码: '登入密码',
  快速注册: '快速注册',
  注册: '注册',
  立即登录: '已有账号，点击登陆',
  请输入邮箱: 'Gmail邮箱地址',
  输入用户名: '输入用户名',
  请输入邀请码: '请输入邀请码',
  返回: '返回',
  密码找回失败: '密码找回失败',
  输入的信息有误: '输入的信息有误',
	获取验证码:"获取验证码",
	手机号码不能为空:"手机号码不能为空",
	账号不存在:"账号不存在",
	发送成功:"发送成功",
	今日收益:"（当前收入）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"您好，如果您中途终止POS，系统将收取5%的POS管理费用。特别提醒：体验的免费节点暂停POS后将无法再次获取，也无法获得任何费用！！",
	我同意:"我同意",
	用户账号ID:"账号ID",
	取消:"取消",
	日收益:"日收益",
	请输入购买数量:"请输入购买数量",
	数量不能为空:"数量不能为0",
	加载中:"加载中...",
	唯一码:"机器人编码",
	未激活:"未激活",
	激活:"激活策略",
	购买时间:"购买时间",
	"钱包余额不足，激活该机器人失败":"账户余额不足，无法进行节点POS挖矿",
	
	运行时长:"运行时长",
	签约购买:"余额购买",
	系统赠送:"系统赠送",
	状态:"状态",
	正常:"正常",
	我的机器人:"我的量化交易机器人",
	一级会员:"一级",
	二级会员:"二级",
	三级会员:"三级",
	人:"",
	充值客服:"充值客服",
	充值:"充值",
	提现:"提现",
	提款密码:"提款密码",
	设置交易密码:"设置交易密码",
	登录密码:"登录密码",
	请输入交易密码:"输入交易密码",
	再次请输入交易密码:"确认交易密码",
	确认: '确认',
	手机号码不能为空:"手机号码不能为空",
	两次密码不一致:"两次密码不一致",
	请输入验证码:"请输入验证码",
	操作成功:"操作成功",
	"用户名或密码不正确,登录失败":"用户名或密码不正确,登录失败",
	登录成功:"登录成功",
	充值说明:"充值说明",
	请先设置支付密码:"请先设置交易密码",
	复制成功:"复制成功",
	冻结机器人做单本金:"冻结量化资金",
	待审核:"待审核...",
	成功:"成功",
	失败:"失败",
	审核中:"审核中",
	在线充值:"在线充值",
	描叙:"描述",
	POS节点挖矿LV0:"POS-LV0节点挖矿",
	POS节点挖矿LV1:"POS-LV1节点挖矿",
	POS节点挖矿LV2:"POS-LV2节点挖矿",
	POS节点挖矿LV3:"POS-LV3节点挖矿",
	POS节点挖矿LV4:"POS-LV4节点挖矿",
	POS节点挖矿LV5:"POS-LV5节点挖矿",
	银行卡提现:"银行卡提现",
	USD提现:"USD提现",
	三级代理:"三级代理",
	一级代理:"一级代理",
	二级代理:"二级代理",
	一级:"一级",
	二级:"二级",
	三级:"三级",
	做单:"量化交易",
	登录失败:"登录失败",
	请勿重复操作:"请勿重复频繁操作",
	邀请码不正确:"邀请码不正确",
	团队返佣:"团队佣金",
	购买机器人返佣:"机器人返佣",
	本金返还:"POS返还",
	佣金收入:"佣金收入",
	时间:"时间",
	机器人做单返还:"POS金额返还",
	涨幅:"涨幅",
	市场:"市场",
	客服:"客服",
	验证码错误:"验证码错误",
  付款方式:"提款方式",
	我的留言:"我的留言",
  暂无数据: '暂无数据',
  银行卡:'银行卡',
  修改: '修改',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助',
 连接借记卡:"连接借记卡",
 银行名称:'银行名称',
 姓名姓氏: '姓名',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* 重要提示:借记卡信息必须真实有效才能取款。',
 帮助中心:'帮助中心',
 会员须知:'会员须知',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'我知道了',
 链接点击:'邀请注册链接：',
 确定:'确定',
 建立自己的团队邀请:'建立自己的团队邀请',
 此账号已冻结:"此账号已冻结",
 手机号存已存在:"手机号存已存在",
 注册账号已存在:"注册账号已存在",
 请确定新密码:"请确定新密码",
 请再次输入登录密码:"请再次输入登录密码",
 密码长度不能少于6位:"密码长度不能少于6位",
 加入:'加入',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"银行卡已被绑定",
 	USD_TRC20提现:'USD_TRC20提现',
 	USD_ERC20提现:'USD_ERC20提现',
 	银行卡提现:'银行卡提现',
 	邀请赠送:'邀请奖金',
 	机器人返佣:'机器人返佣',
 	升级:'成功购买',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "交易序号",
   消息详情:"消息详情",
   个人收益累计:"个人收益累计",
   今日代理收益:"今日代理收益",
   代理累计收益:"代理累计收益",
   截图保存推荐给朋友:"分享您的推荐注册链接邀请好友加入Plus500Ai平台赚取团队收入佣金奖励。 三级团队成员收益提成8%-3%-2%，团队佣金按照下级成员每日收入数据，每日00:00后自动结算进入您的账号余额",
   复制:"复制",
   充值提示1:"提示1:最低充值5 USD起,即可充值,否则不到账",
   充值提示2:"提示2:充值需要链上确认，成功充值后，预计一分钟左右到账钱包余额。",
   请输入正确的充值金额:"充值金额≥8 USD",
   推荐:"推荐",
   充值金额:"充值金额",
   请上传付款截图:"请上传付款成功截图",
   交易号: '交易Hash号',
   充值ID提示:"请黏贴你的Txid交易序列号",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将由用户自己承担。",
	上传凭证:"上传付款凭证",
	充值确认: "提交",
	不能为空:"不能为空",
  绑定钱包地址:'绑定钱包地址',
	备注:"备注",
	请输入银行卡号:"请输入银行卡号",
	请输入你的名字:"请输入你的名字",
	银行卡号:"银行卡号",
	添加银行卡:"添加银行卡",
	请选择银行:"请选择银行",
	请输入钱包地址:"请输入钱包地址",
	钱包地址:"钱包地址",
	"密码错误次数过多,请等待x秒后再试":"密码错误次数过多,请等待600秒后再试",
	"此账号已冻结,请联系客服":"此账号已冻结,请联系客服",
	手机号格式错误:"手机号格式错误",
	"取款须知":"取款须知",
	"须知1":"1. 24小时内使用USD-TRC20实时取款（推荐）",
	"须知2":"2. 周六周日提款",
	"须知3":"*用户周六周日可以提款",
	"须知4":"*周末取款将于周一10AM-8PM到账",
	我知道了:"我知道了",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现5USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低5USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$5，交易手续费为交易金额的$5%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"已绑定",
	去设置:"去设置",
	注册成功:"注册成功",
	汇率:"汇率",
	请输入正确的提现金额:"请输入正确的提现金额",
	提款记录:"提款记录",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"请先绑定钱包地址",
	输入邮箱:"输入邮箱",
	邮箱已存在:"邮箱已存在",
	注册邮箱已存在:"注册邮箱已存在",
	指定用户不存在:"指定用户不存在",
	今日涨幅:"涨跌幅",
	恭喜新用户:"恭喜新用户",
	快捷充币:"存款",
	快速买USD:"USD充值",
	在线支付:"在线支付",
	自动到账:"自动到账",
	线下支付:"线下支付",
	联系客服获取验证码:"联系客服获取验证码",
	获取:"获取",
	退出成功:"退出成功",
	不能低于最小充值:"不能低于最小充值",
	不能低于最小提现金额:"不能低于最小提现金额",
	最小提现:"最小提现",
	设置成功:"设置成功",
	官方充币:"官方充币",
	银行卡充值:"银行卡充值",
	等待审核:"等待审核",
	可提现金额不足:"可提现金额不足",
	未经授权:"未经授权",
	交易密码不正确:"交易密码不正确",
	提现次数不足:"提现次数不足",
	官网:"官网",
	账户USD不足:"账户USD余额不足",
	激活成功: "POS成功",
	操作成功: "操作成功",
	修改成功:"修改成功",
	注册说明:"手机OTP和Email CAPTCHA 只需任选1种方式验证即可成功注册",
	弹窗内容:
	"欢迎来到Plus500Ai 量化交易平台，每位用户可以使用注册奖金1 USD购买NO.0量化机器人交易策略套餐，有效期1天，免费赚取0.5 USD。其他量化交易策略套餐都需要对应费用才能使用。用户购买机器人后需要激活量化交易策略才能自动开始运行，每个交易策略对应不同的有效期限。如果用户购买对应机器人点后没有点击激活策略 ，您将不会获得任何交易收益。",
	标题1:'MARS(My Ai Robots)平台介绍',
	标题2:'AI量化交易的优点',
	标题3:'为什么选择MARS(My Ai Robots)？',
	标题4:'如何加入MARS(My Ai Robots)',
	标题5:'MARS(My Ai Robots)市场前景',
	标题6:'MARS(My Ai Robots)收益模式',
	标题7:'充值与提现条例',
	标题8:"大逃杀游戏玩法",
	内容8:"Plu500Ai平台的大逃杀游戏中有8个房间，玩家在8个房间中任意选择一个房间投放金币，在系统倒计时结束后，游戏系统会出现一个杀手，杀手随机进入一个房间消灭掉房间的人，其他玩家便可以瓜分这个房间的金币，获得盈利。 大逃杀游戏的玩法比较短，一局只有几分钟，玩家能够在闲暇时间玩。瓜分金额 = 本期杀手杀掉的房间总额。实际瓜分金额 = 瓜分金额 * 0.9（扣除销毁的比例为0.1）。瓜分比例 =  实际瓜分金额 / 其他房间总投注金额。中奖金额 = 瓜分比例* 实际投注金额。1金币=1USD, 最低需要10 USD才能参与大逃杀游戏。",
	内容1:'交易平臺由My Ai Robots提供。因此，My Ai Robots是本網站所述或提供的金融產品的發行人和銷售商。My Ai Robots是一家總部位於塞舌耳的公司（公司 編號 8426415-1）其辦事處位於維多利亞。本公司經塞舌耳金融服務管理局（FSA）授權和監管，許可證號為SD039，提供各種基礎產品的差價合約（CFD）。本公司是一家快速發展的CFD供應商，目前提供超過2800種金融工具的投資組合。Plus500SEY Ltd是Plus500 Ltd的子公司，Plus500 Ltd已在倫敦證券交易所的主要市場上市，總部設在海法。',
	内容2:'AI全自动量化交易的核心优势在于能够以高度理性的方式，可以快速分析海量市场信息并实时做出交易决策。与人工操作相比，MARS能够精准捕捉市场中的每一个机会，显著提高交易效率和收益。，完全避免了人工操作中可能出现的情绪干扰。机器人不会受到恐惧、贪婪或主观偏见的影响，从而确保交易决策始终遵循科学的策略和逻辑。这种稳定性是人工操作无法企及的。此外，AI全自动量化交易能够快速处理海量数据，并实时响应市场变化，精准捕捉交易机会。相比之下，人工操作不仅效率较低，还容易受到市场波动、新闻情绪等外部因素的干扰，难以始终如一地按照最优交易策略执行。同时，AI交易系统可以全天候运行，不受时间和精力的限制。这意味着它可以在全球市场不同的交易时段持续监控和执行交易，而人工操作受限于人的体力和精力，难以实现这种不间断的高效工作。更重要的是，AI全自动量化交易能够通过历史数据的深度分析和模型优化，不断提升策略的盈利能力和风险管理水平，而人工操作则常因经验局限或情绪波动，无法在长期维持最佳的盈利模式。',
	内容3:'MARS通过扩展核心产品到新市场和现有市场，推出创新的交易和金融产品，使用户能够轻松参与全球多元化的投资机会。无论是新兴市场还是成熟市场，MARS都能帮助您捕捉每一个潜在收益点。资产安全始终是我们的首要任务，MARS采用行业领先的加密技术，全面保护您的加密密钥和交易数据。此外，MARS提供全球托管服务，受到严格监管和审计，并提供高达5 亿美元的保险保障，让您的资产始终无忧。我们不仅关注资产的安全存储，更注重让您的资产产生价值。通过MARS，您可以根据个人风险承受能力灵活部署资金。从 AI量化交易 到经典金融服务，MARS提供多种投资方式，帮助您的资产实现高效增值。MARS (My Ai Robots) 用科技重新定义资产管理，为您提供更安全、更高效、更智能的财富管理体验。选择MARS，就是选择未来！',
	内容4:'MARS成立以来， 注册需提交用户的手机号码或者邮箱和推荐者的邀请码即可快速注册。 所有与客户的沟通均以书面形式进行，可以是透过电子邮件或线上聊天。 若要透过电子邮件联络MARS，请填写“联络我们”页面上的表格（“申请表”）。 提交申请表后，MARS会透过电子邮件直接发送回复到您在申请表中指定的邮箱地址。 如有疑问请联络客服。',
	内容5:'当前市场对多元化投资工具的需求日益强烈，MARS提供涵盖AI量化交易、传统金融服务以及多资产部署的多样化方案，能够满足不同风险偏好用户的个性化需求。随着金融市场的进一步发展，MARS将吸引更多机构与个人投资者加入。未来，MARS (My Ai Robots) 将持续拓展全球业务版图，优化技术能力，提升用户体验，为用户和合作伙伴带来无限可能。',
	内容6:'MARS (My Ai Robots) 通过先进的AI量化交易技术和多元化资产管理模式，为用户提供全新的财富增值解决方案，让您的资产实现更高效、更稳定的收益。MARS基于大数据分析和模型优化，能够为用户提供更强大的风险管理工具。在市场波动中，系统会动态调整策略，保护用户资产安全，同时确保收益稳定。这种智能化的动态调整能力，是传统投资模式无法企及的。MARS (My Ai Robots)可以让每位用户的资产不仅仅是静态的财富存储，更是不断增值的高效工具。在MARS的赋能下，您可以轻松实现财富管理的智能化、高效化，迈向更富足的未来！',
	内容7:'MARS（My Ai Robots）平台鉴于是开放全球用户使用统一支持美元（USD）充值与提现为标准，部分国家会有专门的充值和提现通道，如后续开通其他通道，官网将发布公告。最低充值和提现金额均为50美元，充值与提现处理时间最快为5分钟，最慢不超过24小时。用户提款时间没有限制，您可以随时申请提款。提款将在正常工作日内于5分钟至24小时内到账，周末提款将统一安排至周一处理。',
	BSC提现:"USD_BSC提现",
	社区Ads收益:"社区Ads收益",
	Ads收益区间:"Ads收益1-1000$",
	申请Ads费用:"申请Ads费用",
	Ads促销地址:"Ads促销地址",
	填写Ads促销地址:"填写Ads促销地址",
	申请Ads费用须知:"申请Ads费用须知",
	须知A:"1. 用户录制促销视频上传发布到自己的YouTube，Facebook,频道或其他社区进行Ads促销，申请$1-$1000奖金，提交申请3天后平台将审核您的Ads视频素材，并根据视频促销效果将Ads奖金打入您的账户余额，无任何促销效果的视频无法通过审核",
	须知B:"2. 同一频道同一Ads促销广告视频只能申请一次奖金，每周可发布一次新的促销视频素材，不要恶意重复发送请求。 如果您恶意多次在平台上提交非本人制作的Ads请求或者审核失败的Ads素材，系统检测后您的帐户将被禁止使用",
	须知C:"3. 用户录制的Ads视频素材需要有基本的平台介绍和平台功能解说，视频时间不短于5分钟，您的频道订阅者数量越多，视频的促销效果越好，可以申请的Ads费用越高。具体Ads费用参考如下，以YOUTUBE为例：",
	须知D: "订阅者0-2K:申请金额1-20$",
	须知D1:"订阅者3K-10K:申请金额21-40$",
	须知D2:"订阅者11K-30K:申请金额41-100$",
	须知D3:"订阅者31K-50K:申请金额101-300$",
	须知D4:"订阅者51K-100K:申请金额301-1000$",
	须知D5:"4. 系统将将于每周日对Ads奖金申请进行审核处理，符合条件的用户将收到系统派送的奖金",
    请求审核:"请求审核",
	申请金额:"申请金额",
	URL促销地址:"URL促销地址",
	提交时间:"提交时间",
	需要更新的银行卡不存在:"需要更新的银行卡不存在",
	银行卡提现:"银行卡提现",
	预计收入:"预计收入",
	总投资:"总投资",
	购买价:"购买价",
	邀请:"邀请",
	成员:"成员",
	积分等级折扣规则介绍3:"该折扣金额仅有效期至2023年12月31日，2024年1月1日后将有新的调整。",
	LV1佣金:"LEVEL1佣金",
	LV2佣金:"LEVEL1佣金",
	LV3佣金:"LEVEL1佣金",
	合格条件:"每人最低充值8USD",
	随时可退:"随时可退",
	登录密码错误:"登录密码错误",
	充值奖金:"充值USD≥50USD,奖金3%",
	充值奖金1:"充值IDR≥50USD,奖金2%",
	充值奖金2:"充值THB≥50USD,奖金5%",
	不能重复发送短信:"10 分钟内不能重复发送短信。 建议您使用EMAIL CAPTCHA直接注册。",
	限购说明1:"您好，NO.0 量化机器人交易策略套餐每个用户只能购买一次",
	活动已过期:"活动已过期",
	修改银行提示:"提款银行账户绑定后无法修改，如需协助，请联系客服",
	修改钱包地址提示:"USD提现地址绑定后无法修改，如需协助，请联系客服",
	注册推送消息1:"欢迎来到INGSAI，新用户启动POS 挖矿节点后，每5分钟获得一次POS 挖矿收入。团队佣金收入每日00.00左右自动进入账户余额",
	限购说明:"您好，该类型量化机器人套餐最大购买次数为10次，建议您购买其他类型量化交易套餐",
	注册协议:"按‘创建帐户’，即表示您确认您已年满18周岁。 您已知悉Plus500AI可能会使用您的电子邮件地址或其他个人资料以电子方式与您联系，提供其产品/服务的相关资讯。 我们也可能会通知您市场事件，帮助您最大限度地利用您的交易经验。 您可以在平台上的'通知设置'和‘隐私设置’选项卡中管理和修改通知首选项。 您可以随时取消订阅这些资讯。 更多资讯，请查阅我们的‘隐私协议’",
	公司说明1:"Plus500AI是Plus500 Ltd 的注册商标。 Plus500 Ltd透过以下子公司进行运营:",
	公司说明2:"Plus500SEY Ltd由塞舌耳金融服务管理局授权和监管（许可证 号 SD039）。",
	公司说明3:"Plus500SEY Ltd是本网站上所描述或可用金融产品的发行人和卖方。",
	公司说明4:"Plus500UK Ltd 由金融行为监管局授权和管理。 金融行为监管局 注册号码：509909. 加密货币CFD未向散户用户提供。",
	公司说明5:"Plus500CY Ltd受塞浦路斯证券及交易委员会授权和监管（授权编码：250/14）。 加密货币CFD未向英国散户提供。",
	公司说明6:"Plus500AU Pty Ltd 持有以下牌照：即ASIC颁发的 AFSL（编号 417727）、纽西兰FMA 颁发的FSP（编号486026），以及南非FSCA 颁发的授权金融服务提供者（编号47546）。",
	公司说明7:"Plus500EE AS 受爱沙尼亚金融监管局授权及监管（牌照号码No. 4.1-1/18）。",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) 持有新加坡金融管理局颁发的资本市场服务执照，允许进行资本市场产品交易（执照号码 CMS100648）。",
	公司说明9:"Plus500AE Ltd由迪拜金融服务管理局（F005651）授权和监管。",
	加入我们的社群:"加入我们的社群",
	PLUS500集团:"PLUS500集团",
	受监管的操作:"受监管的操作",
	健全的全球监管框架:"健全的全球监管框架",
	治理和社会责任:"治理和社会责任",
	底部说明:"底部说明",
	投资者联系方式:"投资者联系方式",
	投资者关系主管:"投资者关系主管",
	AI智慧型量化交易:"AI智慧型量化交易",
	订单执行快速可靠:"订单执行快速可靠",
	全天候自动分析行情:"全天候自动分析行情",
	交易受监管低风险:"交易受监管低风险",
	稳定策略高效收益:"稳定策略高效收益",
	取款快速且安全:"取款快速且安全",
	注册成功通知:"注册成功通知",
	游戏说明:"最低余额为10 USD即可进入游戏并赚钱",
	
	货币:"CNY",
	能量值:"能量值",
	财富值:"财富值",
	佣金:"佣金",
	累计工资:"累计工资",
	工资:"工资",
	租赁:"租赁",
	去租赁:"去租赁",
	
	当前速率:"当前速率",
	当前工资:"当前工资",
	预计今日收益:"预计今日收益",
	累计佣金:"累计佣金",
	邀请好友注册领取工资:'邀请好友注册领取工资',
	月:'月',
	符号:'zh_cn',
	转盘抽奖:'转盘抽奖',
	剩余抽奖次数:'剩余抽奖次数',
	关闭:'关闭',
	恭喜您获得:'恭喜您获得',
	很遗憾没有中奖:'很遗憾没有中奖',
	游戏规则:'游戏规则',
	租赁机器人后会获得抽奖次数:'租赁机器人后会获得抽奖次数',
	中奖金额会存入余额:'中奖金额会存入余额',
	中奖记录请到收入记录中查看:'中奖记录请到收入记录中查看',
	
	可购余额:'可购余额',
	可提现余额:'可提现余额',
	总余额:'总余额',
	去划转:'去划转',
	余额不足:'余额不足',
	小于最低划转金额:'小于最低划转金额',
	余额划转:'余额划转',
	可划转余额:'可划转余额',
	划转金额:'划转金额',
	请输入划转金额:'请输入划转金额',
	确认划转:'确认划转'
	
    
	
}
