<template>
  <div>
    <layout>
      <template #title><img class="team-icon" style="width:111px;height:25px;" src="@/assets/img/about.png" alt="" /></template>
      <template #right>
        <!-- <img
          @click="$refs.popup.show()"
          class="tip"
          src="@/assets/icon/tip.png"
          alt=""
        /> -->
      </template>
      <template #content>
        <div class="content">
          <div class="mInfo ml32 mr32 hide" >
            <div class="flex_row_space-between mt30" >
              <div class="ml32" style="color:#fff;">
                <span class="f46 bold">{{ info.ketixian }}</span>
                <span class="ccolor ml10">USD</span>
              </div>
              <!--<div class="mr32 flexbox">
                <div class="ccolor f24">≈ <span style="font-size: 12px;">Mex$</span></div>
                <div class="ml20 f26">{{ (info.USD * huilv).toFixed(4) }}</div>
              </div>-->
            </div>

            <div class="ccolor flex_row_space-between">
              <div class="ml32">{{ $t('可提现余额') }}</div>
              <div class="mr32 flexbox">{{ $t('汇率') }}</div>
            </div>
            <div class="flex_row_space-between mt30">
              <div class="ml32" style="color:#fff;">{{ $t('提款金额') }}（USD）</div>
              <div class="mr32 flexbox" style="color:#fff;">1 USD≈ <span style="font-size: 12px;">{{info.huilv[$t('符号')]}}{{ $t('货币') }}</span></div>
            </div>
            <div class="flex_row_space-between ml32 mr32 mt30 money box" style="background: hsla(0, 0%, 100%, .5);">
              <div class="flex1" style="height: 1rem;">
                <input style="padding-left: 10px;margin-top: 0.3rem;"
                  class="money"
                 type="number"
                 maxlength="10"
                  :placeholder="$t('请输入提现金额')"
                  v-model="money"
                />
              </div>
              <div class="ccolor f18 mr10" style="margin-right: 0.13333rem;margin-top: 0.1rem;">USD</div>
            </div>
			<div style="text-align: right;width: 95%;line-height: 30px;">≈ {{money * info.huilv[$t('符号')]}}{{ $t('货币') }}</div>
          </div>
         
         <div
            class="add ml32 mr32 mt20 flex_row_space-between"
            @click="select(2)"
          >
            <div class="ml32">
              USD_TRC20 (<span style="font-size: 10px;color:darkorange;"> {{ $t('提现费') }} 2 USD</span>)<div style="padding-top: 10px;">{{$t('最小提现')}} {{info.min_tixian}} USD<span style="font-size: 12px;"></span></div>
              <span
                style="color: red; padding-left: 1rem"
                v-show="!USD_trc20"
                >{{ $t('已绑定') }}</span
              >
            </div>
            <div class="mr32">
              <van-checkbox v-model="checked2"></van-checkbox>
            </div>
          </div> 
		  <div
		     class="add ml32 mr32 mt20 flex_row_space-between"
		     @click="select(1)"
		   >
		  
		     <div class="ml32">
		       USD_ERC20 (<span style="font-size: 10px;color:darkorange;"> {{ $t('提现费') }} 10 USD </span>)
		  			  <div style="padding-top: 10px;">{{$t('最小提现')}} 100 USD<span style="font-size: 12px;"></span></div>
		  			  <span
		         style="color: red; padding-left: 1rem"
		         v-show="!USD_erc20"
		         >{{ $t('已绑定') }}</span
		       >
		     </div>
		     <div class="mr32">
		       <van-checkbox v-model="checked"></van-checkbox>
		     </div>
		   </div> 
          <div
            class="add ml32 mr32 mt20 flex_row_space-between"
            @click="select(3)" v-if="info.bank_tx_off == 1"
          >
            <div class="ml32">
              {{ $t('银行') }} (<span style="font-size: 10px;color:darkorange;"> {{ $t('提现费') }} 10% </span>)<div style="padding-top: 10px;">{{$t('最小提现')}} 5 USD</div>
              <span style="color: red; padding-left: 1rem" v-show="!bankbind">{{
                $t('已绑定')
              }}</span>
            </div>
           <div class="mr32">
              <van-checkbox v-model="checked3"></van-checkbox>
            </div>
            <!--    checked-color="##f3cc2e" -->
          </div>
          <div class="ti mt20 ml14 mr14" style="background-color: #2a2c2e;">
            <div class="f30" style="color:#fff;font-weight:bold;">{{ $t('提现说明') }}</div>
            <div class="f26 ccolor lh20 mt30 mb30" style="color:#0c2780;">
               {{ $t('提现须知') }}<span style="font-size: 12px;"></span>
              			  <br />
                            <span>{{ $t('TIPS1') }}</span
                            ><br />
                            <span>{{ $t('TIPS2') }}</span
                            ><br />
                            <span>{{ $t('TIPS3') }}</span
                            ><br />
              				<span>{{ $t('TIPS4') }}</span
              				><br />
              				<span>{{ $t('TIPS5') }}</span
			><br />
            </div>
            <div class="mt80">
              <hbbtn @clickEvent="confirmEvent">{{ $t('确认提款') }}</hbbtn>
            </div>
          </div>
        </div>
		<ROBOT />
      </template>
    </layout>
    <van-popup v-model="paypwd" round closeable @close="closepaypwd">
      <div
        class="wt-select-dialog"
        style="
          height: 8.00097rem;
          text-align: center;
          background-color: #2a2c2e;
        "
      >
        <br />
        <div class="wt-dialog_bd">
          <p
            style="
              color: #ffffff;
              text-align: center;
              z-index: 10;
              font-size: 18px;
            "
          >
            {{ $t('请输入交易密码') }} <span style="color: #26b77e"></span>
          </p>
          <div class="wt-card" style="min-height: 50px; text-align: center">
            <van-field
              class="input"
              v-model="paypasswd"
              :placeholder="$t('请输入交易密码')"
            />
          </div>
          <van-row>
            <div>
              <van-col span="12" style="width: 90%; padding-top: 20px">
                <van-button
                  type="info"
                  style="width: 5.5rem; background-color: #2a2c2e"
                  @click="tixian2"
                  >{{ $t('确认') }}</van-button
                >
              </van-col>
            </div>
          </van-row>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="tishi" round closeable @close="closetishi" class="toastIndex">
    	  <div class="wt-select-dialog" style=" height: 5.00097rem;text-align: center;background-color: #2a2c2e">
    			  <br>
    			  <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请先设置支付密码")}}</p>
    	      <van-row >
    			<div >
    	        <van-col span="12" style="width: 60%; padding-top: 20px;" @click="gosetting">
    	          <van-button  type="info" style="width: 5.5rem;background-color: #2a2c2e" >{{$t("去设置")}}</van-button>
    	        </van-col>
    			</div>
    	      </van-row>
    	    </div>
    	 
    </van-popup>
    <popup center ref="popup">
      <div class="poptip">
        <img class="img" src="@/assets/icon/tipimg.png" alt="" />
        <div class="f30 mt30" style="color: #fff">{{ $t('取款须知') }}</div>
        <div class="f30 mt10 ccolor">{{ $t('须知1') }}</div>
        <div class="f30 mt10 ccolor">{{ $t('须知2') }}</div>
        <div class="f30 mt10 ccolor">{{ $t('须知3') }}</div>
        <div class="f30 mt10 ccolor">{{ $t('须知4') }}</div>

        <div
          class="flexbox j-end mt20 f30"
          style="color: #fff"
          @click="$refs.popup.hide()"
        >
          {{ $t('我知道了') }}
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
	import ROBOT from '@/components/robot'
import hbbtn from '@/components/hb-btn'
import popup from '@/components/popup/index.vue'
import {
  NavBar,
  Field,
  Button,
  RadioGroup,
  Radio,
  CellGroup,
  Cell,
  Popup,
  Row,
  Col,
  Toast
} from 'vant'
import {
  apiUser_mas2,
  apitixian,
  getUrlKey,
  apigetHuilv,
  apiUser_bank_mas
} from '@/http/api/'
import md5 from 'js-md5'
export default {
  components: {
    hbbtn,
    popup,
	 ROBOT
  },
  data() {
    return {
      checked: false,
      checked2: true,
      checked3: false,
      bankbind: true,
      USD_trc20: true,
      USD_erc20: true,
      info: [],
      huilv: '',
      paypwd: false,
      paypasswd: '',
      tishi: false,
      money: '',
      check: 2,
	  tishi:false
    }
  },
  watch: {},
  methods: {
	  gosetting(){
	  		  this.$router.push('/setPwd');
	  },
	closetishi(){
	  this.tishi = false
	},
    closereset() {
      this.tishi = false
    },
    closepaypwd() {
      this.paypwd = false
    },
    resetpayman() {
      this.$router.push('/setPwd')
    },
    tixian2() {
	
	 
      if (this.money == '') {
        Toast.fail(this.$t('请输入正确的提现金额'))
        return
      }
	  if(this.money < this.info.min_tixian){
		  Toast.fail(this.$t('不能低于最小提现金额'))
		  return
	  }
      if (!this.paypasswd) {
        Toast.fail(this.$t('请输入交易密码'))
        return
      }

      // switch (this.checked) {
      //   case '1':
      //     if (this.USD_erc20) {
      //       this.$router.push({ path: '/bankCard', query: { type: 1 } })
      //       return
      //     }

      //     break
      //   case '2':
      //     if (this.USD_trc20) {
      //       this.$router.push({ path: '/bankCard', query: { type: 2 } })
      //       return
      //     }

      //     break
      //   case '3':
      //     if (this.bankbind) {
      //       this.$router.push({ path: '/bank-bind' })
      //       return
      //     }
      //     break
      // }
      Toast.loading({
        duration: 0,
        message: '',
        forbidClick: true
      })
		
      apitixian({
        userid: this.token,
        type: this.check,
        money: this.money,
        paypasswd: md5(this.paypasswd),
        suihao: this.suihao
      }).then((res) => {
        Toast.clear()
        if (res.status == 1) {
          Toast.success(this.$t(res.info))
          setTimeout(() => {
            this.$router.push({ path: '/recharge', query: { type: 2 } })
          }, 1000)
        } else {
          Toast.fail(this.$t(res.info))
          return
        }
      })
    },
    confirmEvent() {
      if (this.money == '') {
        Toast.fail(this.$t('请输入正确的提现金额'))
        return
      }
      if (this.money < this.info.min_tixian) {
        Toast.fail(this.$t('不能低于最小提现金额'))
        return
      }
      let curmoney = parseInt(this.info.USD)
      if (curmoney < this.money) {
        Toast.fail(this.$t('可提现金额不足'))
        return
      }

      if (this.info.paypassword == '') {
        this.tishi = 1
        return
      }
      this.paypwd = true
    },
    select(index) {
      if (index == 1) {
        this.checked = true
        this.checked2 = false
        this.checked3 = false
        this.check = 1
      } else if (index == 2) {
        this.checked = false
        this.checked2 = true
        this.checked3 = false
        this.check = 2
      } else if (index == 3) {
        this.checked = false
        this.checked2 = false
        this.checked3 = true
        this.check = 3
      }
    }
  },
  created() {
    var token = localStorage.getItem('key')
    console.log('token', token)
    if (!token) {
      this.$router.replace('/login')
    }
    this.token = token
    apiUser_mas2({
      userid: token
    }).then((res) => {
      this.info = res.user
	  // 判断是否设置交易密码
	  if(res.user.paypassword == ""){
	  	 this.tishi = true;
	  }
    })
    var url = window.location.href
    var show = getUrlKey('show2', url)

    apigetHuilv({}).then((res) => {
      this.huilv = res.info
      console.log(res)
    })
    apiUser_bank_mas({
      userid: token
    }).then((res) => {
      let data = res.user

      for (let i = 0; i < data.length; i++) {
        if (data[i].type == 'BANK') {
          this.bankbind = false
        }
        if (data[i].type == 'USD_trc20') {
          this.USD_trc20 = false
        }
        if (data[i].type == 'USD_erc20') {
          this.USD_erc20 = false
        }
      }
      console.log(res)
    })
  }
}
</script>

<style lang="scss" scoped>
.tip {
  width: 38px;
  height: 38px;
}
// ::v-deep .van-icon .van-icon-success {
//   background-color: #f3cc2e !important;
// }

.content {
  .mInfo {
    height: 361px;
    background: linear-gradient(360deg, #fff 1%, #084075 100%);
    border-radius: 10px;
    .money {

      border-radius: 10px;
      background: hsla(0, 0%, 100%, .1);
      color: #000;
      font-size: 25px;
	  font-weight:bold;
    }
  }
  .add {
    height: 126px;
	color:#0f2942;
	font-weight:bold;
    background: linear-gradient(360deg, #fff 1%, #084075 100%);
    border-radius: 10px;
  }
  .ti {
    padding: 40px;
    height: 789px;
    background: linear-gradient(360deg, #fff 1%, #084075 100%);
    border-radius: 10px;
  }
  .poptip {
    margin-left: 32px;
    margin-right: 32px;
    height: 442px;
    background: #2a2c2e;
    position: relative;
    padding: 20px 30px;
    border-radius: 28px;
    .img {
      width: 104px;
      height: 80px;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-0%, -50%);
    }
  }
  ::v-deep .van-icon-success:before {
    color: #fff !important;
  }
  ::v-deep.van-checkbox__icon--checked .van-icon {
    // color: #fff !important;
    background-color: #0D6EFD   !important;
    border-color: #fff !important;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #0d9135;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #0d9135;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #FFFFFF;
  }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #888888;
  }
}
</style>
